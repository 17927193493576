<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Artikelverwaltung</h4>
                            <p>Artikel editieren</p>
                        </div>
                    </div>

                    <div class="card-body">
                        <ul class="nav nav-tabs" id="custom-content-below-tab" tole="tablist">
                            <li class="nav-item">
                                <a href="#custom-content-below-home" id="custom-content-below-home-tab" class="nav-link active" data-toggle="pill" role="tab" aria-controls="custom-content-below-home" aria-selected="true">Stammdaten</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-content-below-image" id="custom-content-below-image-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-image" aria-selected="false">Bilder</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-content-below-variation" id="custom-content-below-variation-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-variation" aria-selected="false" :class="{'disabled': !article.hasVariations}">Varianten</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-content-below-nutrition" id="custom-content-below-nutrition-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-nutrition" aria-selected="false">Nährwerte</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a href="#custom-content-below-crossselling" id="custom-content-below-crossselling-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-crossselling" aria-selected="false">Cross-Selling</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-content-below-other" id="custom-content-below-other-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-other" aria-selected="false">Erweitert</a>
                            </li>
                            <li class="nav-item">
                                <a href="#custom-content-below-marketplace" id="custom-content-below-marketplace-tab" class="nav-link" data-toggle="pill" role="tab" aria-controls="custom-content-below-marketplace" aria-selected="false">Marktplätze</a>
                            </li> -->
                        </ul>
                        <div class="tab-content" id="custom-content-below-tabContent">
                            <div class="tab-pane fade active show" id="custom-content-below-home" role="tabpanel" aria-labelledby="custom-content-below-home-tab">
                                <fieldset class="p-2 border">
                                    <legend class="w-auto">Stammdaten</legend>
                                    <div class="mb-3 row">
                                        <div class="text-right col-md-12">
                                            <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: article.product.id}}" v-if="$auth.check('products.show') && article.product != null"><i class="fas fa-box"></i> Produktstammdatenblatt</router-link>                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">

                                            

                                            <div class="form-group row">
                                                <label for="name" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelname:</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="name" id="name" :class="{'is-invalid': form.errors.has('name')}" v-model="form.name" disabled>
                                                    <has-error :form="form" field="name"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="ordernumber" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelnummer:</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="ordernumber" id="ordernumber" :class="{'is-invalid': form.errors.has('ordernumber')}" v-model="form.ordernumber" disabled>
                                                    <has-error :form="form" field="ordernumber"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="categories" class="control-label col-sm-3 col-form-label col-form-label-sm">Kategorie:</label>
                                                <div class="col-sm-8">
                                                    <!-- <select name="category_id" id="category_id" class="form-control form-control-sm" v-model="form.category_id" :class="{'is-invalid': form.errors.has('category_id')}">
                                                        <option v-for="category in categories" :key="category.id" v-bind:value="category.id">{{category.name}}</option>
                                                    </select> -->
                                                    <Select2 v-model="form.categories" :options="categories" :settings="{ multiple: true }" :class="{'is-invalid': form.errors.has('categories')}" disabled />
                                                    <has-error :form="form" field="categories"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="seo_category" class="control-label col-sm-3 col-form-label col-form-label-sm">SEO-Kategorie:</label>
                                                <div class="col-sm-8">
                                                    <Select2 v-model="form.seo_category" :options="seoCategories" :class="{'is-invalid': form.errors.has('seo_category')}" disabled/>
                                                    <has-error :form="form" field="seo_category"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="active" id="active" :class="{'is-invalid': form.errors.has('active')}" v-model="form.active" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="active"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="hasVariations" class="control-label col-sm-3 col-form-label col-form-label-sm">Varianten</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" @change="toggleHasVariation" value="1" class="form-check-input" name="hasVariations" id="hasVariations" :class="{'is-invalid': form.errors.has('hasVariations')}" v-model="form.hasVariations" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="hasVariations"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="producer_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Hersteller Front:</label>
                                                <div class="col-sm-8">
                                                    <select name="producer_id" id="producer_id" class="form-control form-control-sm" v-model="form.producer_id" :class="{'is-invalid': form.errors.has('producer_id')}" disabled>
                                                        <option v-for="producer in producers" :key="producer.id" v-bind:value="producer.id">{{producer.name}}</option>
                                                    </select>
                                                    <has-error :form="form" field="producer_id"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="distributor_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferant (Bestellung intern):</label>
                                                <div class="col-sm-8">
                                                    <select name="distributor_id_id" id="distributor_id_id" class="form-control form-control-sm" v-model="form.distributor_id" :class="{'is-invalid': form.errors.has('distributor_id')}" disabled>
                                                        <option v-for="distributor in distributors" :key="distributor.id" v-bind:value="distributor.id">{{distributor.name}}</option>
                                                    </select>
                                                    <has-error :form="form" field="distributor_id"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="tax_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">MwSt:</label>
                                                <div class="col-sm-8">
                                                    <div v-for="tax in taxes" :key="tax.id" class="form-check-inline form-check">
                                                        <input v-model="form.tax_id" :value="tax.id" type="radio" name="tax_id" id="tax_id" class="form-check-input" style="position: static; margin-left: 0;" disabled>
                                                        <label  class="control-label form-check-label">{{tax.description}}</label>
                                                    <has-error :form="form" field="tax_id"></has-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="han" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferantenartikelnr.:</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="han" id="han" :class="{'is-invalid': form.errors.has('han')}" v-model="form.han" disabled>
                                                    <has-error :form="form" field="han"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="ean" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">EAN:</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="ean" id="ean" :class="{'is-invalid': form.errors.has('ean')}" v-model="form.ean" disabled>
                                                    <has-error :form="form" field="ean"></has-error>
                                                </div>
                                            </div>

                                            

                                        </div>

                                    </div>
                                </fieldset>
                                <div class="row">
                                    <div class="col-md-6" style="display: flex; flex-direction: column">
                                        <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                            <legend class="w-auto">Artikeleigenschaften</legend>
                                            

                                            <div class="form-group row">
                                                <label for="bio" class="control-label col-sm-3 col-form-label col-form-label-sm">Bio</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="bio" id="bio" :class="{'is-invalid': form.errors.has('bio')}" v-model="form.bio" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="bio"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="vegan" class="control-label col-sm-3 col-form-label col-form-label-sm">Vegan</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="vegan" id="vegan" :class="{'is-invalid': form.errors.has('vegan')}" v-model="form.vegan" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="vegan"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="glutenfrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Glutenfrei</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="glutenfrei" id="glutenfrei" :class="{'is-invalid': form.errors.has('glutenfrei')}" v-model="form.glutenfrei" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="glutenfrei"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="laktosefrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Laktosefrei</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="laktosefrei" id="laktosefrei" :class="{'is-invalid': form.errors.has('laktosefrei')}" v-model="form.laktosefrei" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="laktosefrei"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="topseller" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikel hervorheben?</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="topseller" id="topseller" :class="{'is-invalid': form.errors.has('topseller')}" v-model="form.topseller" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="topseller"></has-error>
                                                </div>
                                            </div>

                                        </fieldset>

                                    </div>
                                    <div class="col-md-6" style="display: flex; flex-direction: column">
                                        <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                            <legend class="w-auto">Versandeigenschaften</legend>
                                            
                                            <div class="form-group row" v-if="funke_mode == 'false'">
                                                <label for="fragile" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Bruchgut je Artikel (4&euro;)*</label>
                                                <div class="col-sm-7">
                                                    <input type="checkbox" class="form-check-input" name="fragile" id="fragile" :class="{'is-invalid': form.errors.has('fragile')}" v-model="form.fragile" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="fragile"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row" v-if="funke_mode == 'false'">
                                                <label for="cargo" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Sperrgut je Artikel (6&euro;)*</label>
                                                <div class="col-sm-7">
                                                    <input type="checkbox" class="form-check-input" name="cargo" id="cargo" :class="{'is-invalid': form.errors.has('cargo')}" v-model="form.cargo" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="cargo"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="cool" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Kühlversand pauschal (4&euro;)*</label>
                                                <div class="col-sm-7">
                                                    <input type="checkbox" class="form-check-input" name="cool" id="cool" :class="{'is-invalid': form.errors.has('cool')}" v-model="form.cool" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="cool"></has-error>
                                                </div>
                                            </div>


                                            <div class="form-group row">
                                                <label for="shippingfree" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Versandfrei</label>
                                                <div class="col-sm-7">
                                                    <input type="checkbox" class="form-check-input" name="shippingfree" id="shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}" v-model="form.shippingfree" style="position: static; margin-left: 0;" disabled>
                                                    <!-- <select name="shippingfree" id="shippingfree" class="form-control form-control-sm" v-model="form.shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}">
                                                        <option value="0">Nein</option>
                                                        <option value="1">Ja</option>
                                                    </select> -->
                                                    <has-error :form="form" field="shippingfree"></has-error>
                                                </div>
                                            </div>
                                            

                                        </fieldset>

                                    </div>
                                </div>


                                <fieldset class="p-2 mt-3 border">
                                    <legend class="w-auto">Preispflege</legend>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                                <input type="text" class="form-control form-control-sm" name="price" id="price" :class="{'is-invalid': form.errors.has('price')}" v-model="form.price" disabled>
                                                <has-error :form="form" field="price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="pseudo_price" class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}" disabled>Sonderpreis (Brutto)</label>
                                                <input type="text" class="form-control form-control-sm" name="pseudo_price" id="pseudo_price" :class="{'is-invalid': form.errors.has('pseudo_price')}" v-model="form.pseudo_price" disabled>
                                                <has-error :form="form" field="pseudo_price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="purchase_price" class="control-label col-form-label col-form-label-sm" v-if="$auth.check('articles.edit')" disabled>Einkaufspreis (Netto)</label>
                                                <input type="text" class="form-control form-control-sm" name="purchase_price" id="purchase_price" v-if="$auth.check('articles.edit')" :class="{'is-invalid': form.errors.has('purchase_price')}" v-model="form.purchase_price" disabled>
                                                <has-error :form="form" field="purchase_price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-1" v-if="notEmptyObject(article)">
                                            <div class="form-group">
                                                <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}" v-if="$auth.check('articles.edit')" disabled>Marge % (Netto)</label>
                                                <input type="number" style="background-color: #D3D3D3; opacity: 0.64;" class="form-control form-control-sm" v-if="$auth.check('articles.edit')" :value="calculateMargePercent()" :class="{'is-invalid': form.errors.has('purchase_price')}" :disabled="true"><br>
                                                <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargePercent() }}</span> -->
                                            </div>
                                        </div>

                                        <div class="col-md-1" v-if="notEmptyObject(article)">
                                            <div class="form-group">
                                                <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}" v-if="$auth.check('articles.edit')" disabled>Marge (absolut)</label>
                                                <input type="number" style="background-color: #D3D3D3; opacity: 0.64;"  class="form-control form-control-sm" v-if="$auth.check('articles.edit')" :value="calculateMargeAbsolut()" :class="{'is-invalid': form.errors.has('purchase_price')}" :disabled="true">
                                                <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargeAbsolut() }}</span> -->
                                            </div>
                                        </div>

                                        <div class="col-md-1">
                                            <div class="form-group">
                                                <label for="store_price" class="control-label col-form-label col-form-label-sm">Filialpreis</label>
                                                <input type="text" class="form-control form-control-sm" disabled name="store_price" id="store_price" :class="{'is-invalid': form.errors.has('store_price')}" v-model="form.store_price">
                                                <has-error :form="form" field="store_price"></has-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="deposit_id" class="control-label col-form-label col-form-label-sm">Pfandart:</label>
                                                    <select name="deposit_id" id="deposit_id" class="form-control form-control-sm" v-model="form.deposit_id" :class="{'is-invalid': form.errors.has('deposit_id')}" disabled>
                                                        <option v-for="deposit in deposits" :key="deposit.id" v-bind:value="deposit.id">{{deposit.name}}</option>
                                                    </select>
                                                    <has-error :form="form" field="deposit_id"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="deposit" class="control-label col-form-label col-form-label-sm">Pfandsumme:</label>
                                                <input type="text" class="form-control form-control-sm" name="deposit" id="deposit" :class="{'is-invalid': form.errors.has('deposit')}" v-model="form.deposit" disabled>
                                                <has-error :form="form" field="deposit"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div><div class="col-md-3"></div>
                                    </div>


                                    <div class="row">

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="article_unit_id" class="control-label col-form-label col-form-label-sm">Grundeinheit:</label>
                                                    <select name="article_unit_id" id="article_unit_id" class="form-control form-control-sm" v-model="form.article_unit_id" :class="{'is-invalid': form.errors.has('article_unit_id')}" disabled>
                                                        <option v-for="unit in units" :key="unit.id" v-bind:value="unit.id">{{unit.description}}</option>
                                                    </select>
                                                    <has-error :form="form" field="article_unit_id"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="purchaseunit" class="control-label col-form-label col-form-label-sm">Verpackung:</label>
                                                    <input type="text" class="form-control form-control-sm" name="purchaseunit" id="purchaseunit" :class="{'is-invalid': form.errors.has('purchaseunit')}" v-model="form.purchaseunit" disabled>
                                                    <has-error :form="form" field="purchaseunit"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">  
                                            <div class="form-group">
                                                <label for="referenceunit" class="control-label col-form-label col-form-label-sm">
                                                    Referenz:
                                                    <i class="fas fa-question-circle" id="tooltip-target-1"></i>
                                                    <b-tooltip target="tooltip-target-1" triggers="hover">
                                                        Bis 250 liegt die Referenz bei 100 und ab 251 bei 1 <br> (z. B. 250 ml >> 100 ml, <br> 0.251 ml >> 1 l
                                                    </b-tooltip>                                                
                                                </label>
                                                <input type="text" class="form-control form-control-sm" name="referenceunit" id="referenceunit" :class="{'is-invalid': form.errors.has('referenceunit')}" v-model="form.referenceunit" disabled>
                                                <has-error :form="form" field="referenceunit"></has-error>
                                            </div>
                                        </div>

                                    </div>                                
                                    
                                </fieldset>

                                <fieldset class="p-2 mt-3 border">
                                    <legend class="w-auto">Lagereigenschaften</legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group row">
                                                <label for="laststock" class="control-label col-sm-3 col-form-label col-form-label-sm">Aus dem Sortiment
                                                <i class="fas fa-question-circle" id="tooltip-ads"></i>
                                                    <b-tooltip target="tooltip-ads" triggers="hover">
                                                        Artikel geht aus dem Sortiment &ndash; in der Front "letzte Chance". Bei Nullbestand Artikel löschen! 
                                                    </b-tooltip>  
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="laststock" id="laststock" :class="{'is-invalid': form.errors.has('laststock')}" v-model="form.laststock" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="laststock"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="not_available" class="control-label col-sm-3 col-form-label col-form-label-sm">Kurzfristig nicht lieferbar
                                                    <i class="fas fa-question-circle" id="tooltip-not-available"></i>
                                                    <b-tooltip target="tooltip-not-available" triggers="hover">
                                                        Zur Zeit nicht lieferbar - in der Font ist der Artikel nicht mehr bestellbar (Button deaktiviert), wird aber trotzdem noch angezeigt
                                                    </b-tooltip>  
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="not_available" id="not_available" :class="{'is-invalid': form.errors.has('not_available')}" v-model="form.not_available" style="position: static; margin-left: 0;" disabled>
                                                    <has-error :form="form" field="not_available"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="shippingtime" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">
                                                    Lieferzeit (in Tagen) 
                                                    <i class="fas fa-question-circle" v-b-tooltip.hover title="Nur die Anzahl der Tage eintragen (z. B. 1 oder 1-4) Voreingestellt: 1-4 Tage"></i>
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="shippingtime" id="shippingtime" :class="{'is-invalid': form.errors.has('shippingtime')}" v-model="form.shippingtime" disabled>
                                                    <has-error :form="form" field="shippingtime"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="publishDate" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Einstelldatum
                                                    <i class="fas fa-question-circle" id="tooltip-publishDate"></i>
                                                    <b-tooltip target="tooltip-publishDate" triggers="hover">
                                                        Ab diesem Tag, ist der Artikel in der Front sichtbar. Allerdings kann der Artikel erst bestellt werden, wenn der Artikel lieferbar ist ("Lieferbar ab").
                                                    </b-tooltip> 
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="date" class="form-control form-control-sm" name="publishDate" id="publishDate" :class="{'is-invalid': form.errors.has('publishDate')}" v-model="form.publishDate" disabled>
                                                    <has-error :form="form" field="publishDate"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="releaseDate" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferbar ab
                                                <i class="fas fa-question-circle" id="tooltip-releaseDate"></i>
                                                    <b-tooltip target="tooltip-releaseDate" triggers="hover">
                                                        Ab diesem Tag ist der Artikel bestellbar. (Funktioniert in Kombination mit dem Einstelldatum)
                                                    </b-tooltip> 
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="date" class="form-control form-control-sm" name="releaseDate" id="releaseDate" :class="{'is-invalid': form.errors.has('releaseDate')}" v-model="form.releaseDate" disabled>
                                                    <has-error :form="form" field="releaseDate"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="mhd" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">MHD kritisch
                                                <i class="fas fa-question-circle" id="tooltip-mhd"></i>
                                                    <b-tooltip target="tooltip-mhd" triggers="hover">
                                                        MHD wird nach dem Artikelname angezeigt  
                                                    </b-tooltip>  
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="date" class="form-control form-control-sm" name="mhd" id="mhd" :class="{'is-invalid': form.errors.has('mhd')}" v-model="form.mhd" disabled>
                                                    <has-error :form="form" field="mhd"></has-error>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </fieldset>

                                    
                                <fieldset class="p-2 mt-3 border">
                                    <legend class="w-auto">Beschreibung</legend>
                                    <div class="form-group">
                                        <label for="description_long" class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description_long" :class="{'is-invalid': form.errors.has('description_long')}" disabled></ckeditor>
                                        <has-error :form="form" field="description_long"></has-error>
                                    </div>
                                </fieldset>


                                
                                
                                </div>
                                <div class="tab-pane fade" id="custom-content-below-image" role="tabpanel" aria-labelledby="custom-content-below-image-tab">
                                    <div class="row ">
                                        <div class="col-md-6">
                                            <fieldset class="p-2 mt-3 border">
                                            <legend class="w-auto">Bilder hochladen</legend>
                                                <div class="form-group">
                                                    <label for="logo" class="control-label">Bilder</label>
                                                    <b-form-file multiple id="images" v-model="uploadImageForm.images" :class="{'is-invalid': uploadImageForm.errors.has('images')}" disabled />
                                                    <has-error :form="uploadImageForm" field="images"></has-error>
                                                    <input type="hidden" v-model="uploadImageForm.article_id" name="article_id" id="article_id" disabled />
                                                </div>

                                                <div class="form-group">
                                                    <button type="button" class="btn btn-primary btn-sm" @click="saveImages" disabled>
                                                            <i class="fas fa-save"></i> Bilder hochladen
                                                    </button>
                                                </div>
                                            </fieldset>

                                            <fieldset class="p-2 mt-3 border">
                                            <legend class="w-auto">Zugewiesene Bilder</legend>

                                                <div class="row" v-if="article.media != null">
                                                    
                                                    <div class="mb-2 text-center border col-md-4 border-primary"  :class="{ 'border-white': imageSelected != index}" @click="selectImage(index)" v-for="(image, index) in article.media" :key="image.uuid" style="width:180px; height: 180px; border-width: 3px;">
                                                        <img :src="image.original_url" style="height: 100%;"/>
                                                        <div class="ribbon-wrapper ribbon-lg" v-if="image.custom_properties.preview == 'true'">
                                                            <div class="ribbon bg-primary">
                                                            Vorschau
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <div class="row" v-if="article.media == []">
                                                    <h6>Es wurde noch keine Bilder für diesen Artikel hochgeladen</h6>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="col-md-6">
                                            <fieldset class="p-2 mt-3 border" v-if="selectedImage.original_url != null">
                                            <legend class="w-auto">Bildoptionen</legend>
                                                <div class="row">
                                                    <div class="text-center col-md-12">
                                                        <img :src="selectedImage.original_url" style="width: 50%;"/>
                                                        
                                                    </div>
                                                </div>
                                                <div class="mt-3 row">
                                                    <div class="col-md-12">
                                                        <div class="text-left form-group row">
                                                            <label for="title" class="control-label col-sm-2 col-form-label col-form-label-sm">Titel:</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" name="title" id="title" class="form-control form-control-sm" v-model="imageDataForm.title" :class="{'is-invalid': imageDataForm.errors.has('title')}" disabled>
                                                                <has-error :form="imageDataForm" field="title"></has-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-3 row">
                                                    <div class="text-right col-md-12">
                                                        <button type="button" class="mr-1 btn btn-danger btn-sm" @click="deleteImage(selectedImage.uuid)" v-if="selectedImage.custom_properties.preview == 'false'" disabled>
                                                            <i class="fas fa-trash"></i> Löschen
                                                        </button>
                                                        <button type="button" class="mr-1 btn btn-primary btn-sm" @click="setDefaultImage(selectedImage.uuid)" v-if="selectedImage.custom_properties.preview == 'false'" disabled>
                                                            <i class="fas fa-save"></i> Als Standard festlegen
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm" @click="saveImageData" disabled>
                                                            <i class="fas fa-save"></i> Speichern
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="custom-content-below-variation" role="tabpanel" aria-labelledby="custom-content-below-variation-tab">
                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Vorhandene Varianten</legend>
                                        <b-col md="12" class="mb-2 text-right">
                                            <!-- <b-button size="sm" variant="primary" @click="createModal">Variante erstellen</b-button> -->
                                        </b-col>
                                        <b-row v-if="existingVariations.length >= 1">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Bestellnummer</th>
                                                    <th v-for="group in article.variation_groups" :key="group.id">{{ group.name }}</th>
                                                    <th>Preis</th>
                                                    <th>Angebotspreis</th>
                                                    <th>Vorauswahl</th>
                                                    <th>Aktiv</th>
                                                    <!-- <th>Aktion</th> -->
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(existingVariation, index) in existingVariations" :key="existingVariation.id">
                                                    <td>{{existingVariation.ordernumber}}</td>
                                                    <td v-for="group in article.variation_groups" :key="group.id">
                                                        {{ getVariationValue(index, group.id) }}
                                                    </td>
                                                    <td>{{ existingVariation.price | toCurrency }}</td>
                                                    <td>{{existingVariation.pseudo_price | toCurrency }}</td>
                                                    <td>
                                                        <i v-if="existingVariation.id == article.main_detail_id" class="fas fa-check"></i>
                                                        <i v-else class="fas fa-times"></i>
                                                    </td>
                                                    <td>
                                                        <i v-if="existingVariation.active == 1" class="fas fa-check"></i>
                                                        <i v-else class="fas fa-times"></i>
                                                    </td>
                                                    <!-- <td>
                                                        <b-button size="sm" @click="editModal(existingVariation)" class="mr-1" variant="warning" v-if="$auth.check('articles.edit')"><i class="fas fa-edit" ></i></b-button> -->
                                                        <!-- <b-button size="sm" @click="deleteVariationValue(existingVariation.id)" variant="danger" v-if="$auth.check('articles.destroy')"><i class="fas fa-trash"></i></b-button> -->
                                                    <!-- </td> -->
                                                </tr>
                                            </tbody>

                                        </table>
                                        </b-row>

                                        <b-row v-if="existingVariations.length == 0">
                                            <b-col><h6><i>Es sind derzeit keine Varianten angelegt</i></h6></b-col>
                                        </b-row>
                                    </fieldset>
                                </div>
                                <div class="tab-pane fade" id="custom-content-below-nutrition" role="tabpanel" aria-labelledby="custom-content-below-nutrition-tab">
                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Nährwerte</legend>
                                        <div class="row">
                                            <div class="col-md-6">
                                                
                                                <div class="form-group row">
                                                    <label for="kj" class="control-label col-sm-2 col-form-label col-form-label-sm">KJ</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kj" id="kj" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kj')}" v-model="nutrition.kj" disabled />
                                                        <has-error :form="nutrition" field="kj"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="kcal" class="control-label col-sm-2 col-form-label col-form-label-sm">Kcal</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kcal" id="kcal" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kcal')}" v-model="nutrition.kcal" disabled />
                                                        <has-error :form="nutrition" field="kcal"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="fett" class="control-label col-sm-2 col-form-label col-form-label-sm">Fett</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="fett" id="fett" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('fett')}" v-model="nutrition.fett" disabled />
                                                        <has-error :form="nutrition" field="fett"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="fettsaeuren" class="control-label col-sm-2 col-form-label col-form-label-sm">gesättigte Fettsäuren</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="fettsaeuren" id="fettsaeuren" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('fettsaeuren')}" v-model="nutrition.fettsaeuren" disabled />
                                                        <has-error :form="nutrition" field="fettsaeuren"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="kohlenhydrate" class="control-label col-sm-2 col-form-label col-form-label-sm">Kohlenhydrate</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kohlenhydrate" id="kohlenhydrate" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kohlenhydrate')}" v-model="nutrition.kohlenhydrate" disabled />
                                                        <has-error :form="nutrition" field="kohlenhydrate"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="zucker" class="control-label col-sm-2 col-form-label col-form-label-sm">Zucker</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="zucker" id="zucker" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('zucker')}" v-model="nutrition.zucker" disabled />
                                                        <has-error :form="nutrition" field="zucker"></has-error>
                                                    </div>
                                                </div> 

                                            </div>

                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="eiweiss" class="control-label col-sm-2 col-form-label col-form-label-sm">Eiweiss</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="eiweiss" id="eiweiss" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('eiweiss')}" v-model="nutrition.eiweiss" disabled />
                                                        <has-error :form="nutrition" field="eiweiss"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="salz" class="control-label col-sm-2 col-form-label col-form-label-sm">Salz</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="salz" id="salz" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('salz')}" v-model="nutrition.salz" disabled />
                                                        <has-error :form="nutrition" field="salz"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="ballaststoffe" class="control-label col-sm-2 col-form-label col-form-label-sm">Ballaststoffe</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="ballaststoffe" id="ballaststoffe" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('ballaststoffe')}" v-model="nutrition.ballaststoffe" disabled />
                                                        <has-error :form="nutrition" field="ballaststoffe"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="alkohol" class="control-label col-sm-2 col-form-label col-form-label-sm">Alkohol</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="alkohol" id="alkohol" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('alkohol')}" v-model="nutrition.alkohol" disabled />
                                                        <has-error :form="nutrition" field="alkohol"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="nutri_score" class="control-label col-sm-2 col-form-label col-form-label-sm">Nutriscore</label>
                                                    <div class="col-sm-9">
                                                        <select name="nutri_score" id="nutri_score" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('nutri_score')}" v-model="nutrition.nutri_score" disabled>
                                                            <option value="a">A</option>
                                                            <option value="b">B</option>
                                                            <option value="c">C</option>
                                                            <option value="d">D</option>
                                                            <option value="e">E</option>
                                                        </select>
                                                        <has-error :form="nutrition" field="nutri_score"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="je" class="control-label col-sm-2 col-form-label col-form-label-sm">Je</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="je" id="je" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('je')}" v-model="nutrition.je" disabled />
                                                        <has-error :form="nutrition" field="je"></has-error>
                                                    </div>
                                                </div> 

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="p-2 border">
                                        <legend class="w-auto">Zusatzangaben</legend>
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="zutaten" class="control-label col-sm-2 col-form-label col-form-label-sm">Zutaten</label>
                                                    <div class="col-sm-9">
                                                        <ckeditor :editor="editor" :config="editorConfig" v-model="nutrition.zutaten" :class="{'is-invalid': nutrition.errors.has('zutaten')}" disabled></ckeditor>
                                                        <has-error :form="nutrition" field="zutaten"></has-error>
                                                    </div>
                                                </div> 
                                                
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label for="inverkehrbringer" class="control-label col-sm-2 col-form-label col-form-label-sm">Inverkehrbringer</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="inverkehrbringer" id="inverkehrbringer" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('inverkehrbringer')}" v-model="nutrition.inverkehrbringer" disabled />
                                                        <has-error :form="nutrition" field="inverkehrbringer"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="herkunft" class="control-label col-sm-2 col-form-label col-form-label-sm">Herkunft</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="herkunft" id="herkunft" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('herkunft')}" v-model="nutrition.herkunft" disabled />
                                                        <has-error :form="nutrition" field="herkunft"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="bez" class="control-label col-sm-2 col-form-label col-form-label-sm">Bezeichnung</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="bez" id="bez" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('bez')}" v-model="nutrition.bez" disabled />
                                                        <has-error :form="nutrition" field="bez"></has-error>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                
                                <!-- <div class="tab-pane fade" id="custom-content-below-crossselling" role="tabpanel" aria-labelledby="custom-content-below-crossselling-tab">
                                    <similarArticles :articles="article.similar" />
                                </div>

                                <div class="tab-pane fade" id="custom-content-below-other" role="tabpanel" aria-labelledby="custom-content-below-other-tab">
                                    <Advanced :article="article" v-if="notEmptyObject(article)"/>
                                </div>

                                <div class="tab-pane fade" id="custom-content-below-marketplace" role="tabpanel" aria-labelledby="custom-content-below-marketplace-tab">
                                    <Marketplace :article="article" v-if="notEmptyObject(article)"/>
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <b-modal id="variationModal" title="Artikelvariante anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="sm" @ok="handleOk">
                <form ref="valueForm" @submit.stop.prevent="handleSubmit">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Variantengruppe</label>
                                <div class="col-sm-12">                                    
                                    <select class="form-control form-control-sm" @change="updateValues">
                                        <option v-for="variationGroup in variationGroups" :key="variationGroup.id" :value="variationGroup.id">{{variationGroup.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label col-sm-2 col-form-label col-form-label-sm">Variantenoptionen</label>
                                <div class="form-check" v-for="variationValue in variationValues" :key="variationValue.id">
                                    <input type="checkbox" class="form-check-input" :value="variationValue.id" v-model="variationForm.selectedVariationValues" :class="{'is-invalid': variationForm.errors.has('selectedVariationValues')}">
                                    <label class="form-check-label">{{variationValue.name}}</label>
                                    <has-error :form="variationForm" field="selectedVariationValues"></has-error>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal> -->

            <!-- <b-modal id="editModal" title="Artikeldetails editieren" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleDetailOk($event, detailForm.id)">
                <form ref="valueForm" @submit.stop.prevent="handleDetailSubmit">
                    <fieldset class="p-2 border">
                                    <legend class="w-auto">Stammdaten</legend>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="ordernumber" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelnummer*</label>
                                                <div class="col-sm-9">
                                                    <input type="text" class="form-control form-control-sm" name="ordernumber" id="ordernumber" :class="{'is-invalid': detailForm.errors.has('ordernumber')}" v-model="detailForm.ordernumber">
                                                    <has-error :form="detailForm" field="ordernumber"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                                <div class="col-sm-9">
                                                    <input type="checkbox" class="form-check-input" name="active" id="active" :class="{'is-invalid': detailForm.errors.has('active')}" v-model="detailForm.active" style="position: static; margin-left: 0;">
                                                    <has-error :form="detailForm" field="active"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="han" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferantenartikelnr.</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="han" id="han" :class="{'is-invalid': detailForm.errors.has('han')}" v-model="detailForm.han">
                                                    <has-error :form="detailForm" field="han"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="ean" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">EAN</label>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control form-control-sm" name="ean" id="ean" :class="{'is-invalid': detailForm.errors.has('ean')}" v-model="detailForm.ean">
                                                    <has-error :form="detailForm" field="ean"></has-error>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                                <fieldset class="p-2 border">
                                    <legend class="w-auto">Versandeigenschaften</legend>
                                    <div class="row">
                                        
                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="fragile" class="control-label col-sm-4 col-form-label col-form-label-sm">Bruchgut je Artikel (4&euro;)*</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="fragile" id="fragile" :class="{'is-invalid': detailForm.errors.has('fragile')}" v-model="detailForm.fragile" style="position: static; margin-left: 0;">
                                                    <has-error :form="detailForm" field="fragile"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="cargo" class="control-label col-sm-4 col-form-label col-form-label-sm">Sperrgut je Artikel (6&euro;)*</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="cargo" id="cargo" :class="{'is-invalid': detailForm.errors.has('cargo')}" v-model="detailForm.cargo" style="position: static; margin-left: 0;">
                                                    <has-error :form="detailForm" field="cargo"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="shippingfree" class="control-label col-sm-4 col-form-label col-form-label-sm">Versandfrei</label>
                                                <div class="col-sm-8">
                                                    <input type="checkbox" class="form-check-input" name="shippingfree" id="shippingfree" :class="{'is-invalid': detailForm.errors.has('shippingfree')}" v-model="detailForm.shippingfree" style="position: static; margin-left: 0;">
                                                    <select name="shippingfree" id="shippingfree" class="form-control form-control-sm" v-model="form.shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}">
                                                        <option value="0">Nein</option>
                                                        <option value="1">Ja</option>
                                                    </select>
                                                    <has-error :form="detailForm" field="shippingfree"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </fieldset>

                                <fieldset class="p-2 mt-3 border">
                                    <legend class="w-auto">Preispflege</legend>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                                <input type="text" class="form-control form-control-sm" name="price" id="price" :class="{'is-invalid': detailForm.errors.has('price')}" v-model="detailForm.price">
                                                <has-error :form="detailForm" field="price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="pseudo_price" class="control-label col-form-label col-form-label-sm">Aktionspreis (Brutto)</label>
                                                <input type="text" class="form-control form-control-sm" name="pseudo_price" id="pseudo_price" :class="{'is-invalid': detailForm.errors.has('pseudo_price')}" v-model="detailForm.pseudo_price">
                                                <has-error :form="detailForm" field="pseudo_price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="purchase_price" class="control-label col-form-label col-form-label-sm">Einkaufspreis (Netto)</label>
                                                <input type="number" class="form-control form-control-sm" name="purchase_price" id="purchase_price" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" v-model="detailForm.purchase_price">
                                                <has-error :form="detailForm" field="purchase_price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-2" v-if="notEmptyObject(article)">
                                            <div class="form-group">
                                                <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': detailForm.pseudo_price >0}">Marge % (Netto)</label>
                                                <input type="number" style="background-color: #D3D3D3; opacity: 0.64;" class="form-control form-control-sm" :value="calculateMargePercentDetail()" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" :disabled="true"><br>
                                                <has-error :form="detailForm" field="pseudo_price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-2" v-if="notEmptyObject(article)">
                                            <div class="form-group">
                                                <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': detailForm.pseudo_price >0}">Marge (absolut)</label>
                                                <input type="number" style="background-color: #D3D3D3; opacity: 0.64;"  class="form-control form-control-sm" :value="calculateMargeAbsolutDetail()" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" :disabled="true">
                                                <has-error :form="detailForm" field="pseudo_price"></has-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="deposit_id" class="control-label col-form-label col-form-label-sm">Pfandart*</label>
                                                    <select name="deposit_id" id="deposit_id" class="form-control form-control-sm" v-model="detailForm.deposit_id" :class="{'is-invalid': detailForm.errors.has('deposit_id')}">
                                                        <option v-for="deposit in deposits" :key="deposit.id" v-bind:value="deposit.id">{{deposit.name}}</option>
                                                    </select>
                                                    <has-error :form="detailForm" field="deposit_id"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="deposit" class="control-label col-form-label col-form-label-sm">Pfandsumme</label>
                                                <input type="text" class="form-control form-control-sm" name="deposit" id="deposit" :class="{'is-invalid': detailForm.errors.has('deposit')}" v-model="detailForm.deposit">
                                                <has-error :form="detailForm" field="deposit"></has-error>
                                            </div>
                                        </div>
                                        <div class="col-md-3"></div><div class="col-md-3"></div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="article_unit_id" class="control-label col-form-label col-form-label-sm">Grundeinheit*</label>
                                                    <select name="article_unit_id" id="article_unit_id" class="form-control form-control-sm" v-model="detailForm.article_unit_id" :class="{'is-invalid': detailForm.errors.has('article_unit_id')}">
                                                        <option v-for="unit in units" :key="unit.id" v-bind:value="unit.id">{{unit.description}}</option>
                                                    </select>
                                                    <has-error :form="detailForm" field="article_unit_id"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="purchaseunit" class="control-label col-form-label col-form-label-sm">Verpackung</label>
                                                    <input type="text" class="form-control form-control-sm" name="purchaseunit" id="purchaseunit" :class="{'is-invalid': detailForm.errors.has('purchaseunit')}" v-model="detailForm.purchaseunit">
                                                    <has-error :form="detailForm" field="purchaseunit"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-3">  
                                            <div class="form-group">
                                                <label for="referenceunit" class="control-label col-form-label col-form-label-sm">
                                                    Referenz
                                                    <i class="fas fa-question-circle" id="tooltip-target-1"></i>
                                                    <b-tooltip target="tooltip-target-1" triggers="hover">
                                                        Bis 250 liegt die Referenz bei 100 und ab 251 bei 1 <br> (z. B. 250 ml >> 100 ml, <br> 0.251 ml >> 1 l
                                                    </b-tooltip>                                                
                                                </label>
                                                <input type="text" class="form-control form-control-sm" name="referenceunit" id="referenceunit" :class="{'is-invalid': detailForm.errors.has('referenceunit')}" v-model="detailForm.referenceunit">
                                                <has-error :form="detailForm" field="referenceunit"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </fieldset>

                                <fieldset class="p-2 mt-3 border">
                                    <legend class="w-auto">Lagereigenschaften</legend>
                                    <div class="row">
                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="laststock" class="control-label col-sm-5 col-form-label col-form-label-sm">Aus dem Sortiment
                                                    <i class="fas fa-question-circle" id="tooltip-ads"></i>
                                                    <b-tooltip target="tooltip-ads" triggers="hover">
                                                        Artikel geht aus dem Sortiment &ndash; in der Front "letzte Chance". Bei Nullbestand Artikel löschen! 
                                                    </b-tooltip>  
                                                </label>
                                                <div class="col-sm-6">
                                                    <input type="checkbox" class="form-check-input" name="laststock" id="laststock" :class="{'is-invalid': detailForm.errors.has('laststock')}" v-model="detailForm.laststock" style="position: static; margin-left: 0;">
                                                    <has-error :form="detailForm" field="laststock"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="not_available" class="control-label col-sm-5 col-form-label col-form-label-sm">Kurzfristig nicht lieferbar
                                                    <i class="fas fa-question-circle" id="tooltip-not-available"></i>
                                                    <b-tooltip target="tooltip-not-available" triggers="hover">
                                                        Zur Zeit nicht lieferbar - in der Font ist der Artikel nicht mehr bestellbar (Button deaktiviert), wird aber trotzdem noch angezeigt
                                                    </b-tooltip> 
                                                </label>
                                                <div class="col-sm-6">
                                                    <input type="checkbox" class="form-check-input" name="not_available" id="not_available" :class="{'is-invalid': detailForm.errors.has('not_available')}" v-model="detailForm.not_available" style="position: static; margin-left: 0;">
                                                    <has-error :form="detailForm" field="not_available"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-md-6">

                                            <div class="form-group row">
                                                <label for="shippingtime" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">
                                                    Lieferzeit (in Tagen) 
                                                    <i class="fas fa-question-circle" v-b-tooltip.hover title="Nur die Anzahl der Tage eintragen (z. B. 1 oder 1-4) Voreingestellt: 1-4 Tage"></i>
                                                </label>
                                                <div class="col-sm-7">
                                                    <input type="text" class="form-control form-control-sm" name="shippingtime" id="shippingtime" :class="{'is-invalid': detailForm.errors.has('shippingtime')}" v-model="detailForm.shippingtime">
                                                    <has-error :form="detailForm" field="shippingtime"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="publishDate" class="control-label col-sm-4 offset-md-1 col-form-label col-form-label-sm">Einstelldatum
                                                    <i class="fas fa-question-circle" id="tooltip-publishDate"></i>
                                                    <b-tooltip target="tooltip-publishDate" triggers="hover">
                                                        Ab diesem Tag, ist der Artikel in der Front sichtbar. Allerdings kann der Artikel erst bestellt werden, wenn der Artikel lieferbar ist ("Lieferbar ab").
                                                    </b-tooltip> 
                                                </label>
                                                <div class="col-sm-7">
                                                    <input type="date" class="form-control form-control-sm" name="publishDate" id="publishDate" :class="{'is-invalid': detailForm.errors.has('publishDate')}" v-model="detailForm.publishDate">
                                                    <has-error :form="detailForm" field="publishDate"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="releaseDate" class="control-label col-sm-4 offset-md-1 col-form-label col-form-label-sm">Lieferbar ab
                                                    <i class="fas fa-question-circle" id="tooltip-releaseDate"></i>
                                                    <b-tooltip target="tooltip-releaseDate" triggers="hover">
                                                        Ab diesem Tag ist der Artikel bestellbar. (Funktioniert in Kombination mit dem Einstelldatum)
                                                    </b-tooltip> 
                                                </label>
                                                <div class="col-sm-7">
                                                    <input type="date" class="form-control form-control-sm" name="releaseDate" id="releaseDate" :class="{'is-invalid': detailForm.errors.has('releaseDate')}" v-model="detailForm.releaseDate">
                                                    <has-error :form="detailForm" field="releaseDate"></has-error>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="mhd" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">MHD kritisch</label>
                                                <div class="col-sm-7">
                                                    <input type="date" class="form-control form-control-sm" name="mhd" id="mhd" :class="{'is-invalid': detailForm.errors.has('mhd')}" v-model="detailForm.mhd">
                                                    <has-error :form="detailForm" field="mhd"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </fieldset>


                </form>
            </b-modal> -->

    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';



export default {
    name: 'articles.details',
    title: 'Artikel bearbeiten',
    data() {
        return {
            funke_mode: process.env.VUE_APP_FUNKE_MODE, 
            imageSelected: false,
            selectedImage: {},
            uploadImageForm: new window.UploadForm({
                images: [],
                article_id: "",
            }),
            form: new window.Form({
                id: "",
                main_detail_id: "",
                producer_id: "",
                distributor_id: "",
                name: "",
                ordernumber: "",
                categories: [],
                seo_category: "",
                tax_id: "",
                han: "",
                ean: "",
                description: "",
                description_long: "",
                article_unit_id: "",
                purchaseunit: "",
                referenceunit: "",
                deposit_id: "",
                deposit: "",
                fragile:"",
                cargo:"",
                cool:"",
                maxpurchase:"",
                minpurchase:"",
                minstock:"",
                shippingtime:"",
                publishDate:"",
                releaseDate:"",
                shippingfree:"0",
                topseller:"0",
                laststock:"0",
                weight:"",
                width:"",
                height:"",
                length:"",
                price: "",
                pseudo_price: "",
                purchase_price: "",
                hasVariations:0,
                active: "",
                bio: "",
                vegan: "",
                glutenfrei: "",
                laktosefrei: "",
                mhd: "",
                not_available: "",
                store_price: "",
            }),
            imageDataForm: new window.Form({
                uuid: "",
                title: "",
            }),
            detailForm: new window.Form({
                id: "",
                main_detail_id: "",
                ordernumber: "",
                han: "",
                ean: "",
                article_unit_id: "",
                purchaseunit: "",
                referenceunit: "",
                deposit_id: "",
                deposit: "",
                fragile:"",
                cargo:"",
                maxpurchase:"",
                minpurchase:"",
                minstock:"",
                shippingtime:"",
                publishDate:"",
                releaseDate:"",
                shippingfree:"0",
                topseller:"0",
                laststock:"0",
                weight:"",
                width:"",
                height:"",
                length:"",
                active: "",
                price: "",
                pseudo_price: "",
                purchase_price: "",
                not_available: "", 
                mhd: "", 
            }),
            nutrition: new window.Form({
                'kj': "",
                'kcal': "",
                'fett': "",
                'fettsaeuren': "",
                'kohlenhydrate': "",
                'zucker': "",
                'eiweiss': "",
                'salz': "",
                'ballaststoffe': "",
                'alkohol': "",
                'nutri_score': "",
                'je': "",
                'zutaten': "",
                'inverkehrbringer': "",
                'herkunft': "",
                'bez': "",

            }),

            variationForm: new window.Form({
                'selectedVariationValues': [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            article: {},
            producers: [],
            taxes: [],
            units: [],
            deposits: [],
            categories: [],
            seoCategories: [],
            customerGroups: [],
            variationGroups: {},
            variationValues: {},
            existingVariations: [
                // eslint-disable-next-line no-unused-vars
                values => null,
            ],
            
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "ordernumber", label: "Artikelnummer", sortable: true},
                {key: "values.name", label: "Variante", sortable: true},
                {key: "price", label: "Preis", sortable: true},
                {key: "pseudo_price", label: "Aktionspreis", sortable: true, formatter: 'pseudoPrice'},
                {key: "active", label: "Aktiv", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            distributors: [],
        }
    },

    watch:{

        // form:{
        //     handler(){
        //         this.calculateMargePercent(); 
        //         this.calculateMargeAbsolut(); 
        //     },
        //     deep:true
        // }

        'form.price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
        },

        'form.pseudo_price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
        }, 

        'form.purchase_price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
        }, 

        'detailForm.price': function(){
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        },

        'detailForm.pseudo_price': function(){
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        }, 

        'detailForm.purchase_price': function(){
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        }

    },

    methods: {

        calculateMargePercent()
        {
            var total=0; 

            var margeabsolut=((parseFloat(this.form.price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 
            var margeabsolutpseudo=((parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 

            // 100 / VK netto * EK netto 

            if (this.form.pseudo_price <= 0 ) {
            total=100 / (parseFloat(this.form.price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolut
            }

            else {
            total=100 / (parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolutpseudo
            }

            return total.toFixed(2); 
        },

        calculateMargeAbsolut()
        {
            var total=0; 

            // 100 / VK netto * EK netto 

            if (this.form.pseudo_price <= 0 ) {
            total=((parseFloat(this.form.price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price) 
            }

            else {
            total=(parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) - parseFloat(this.form.purchase_price) 
            }

            return total.toFixed(2); 
        },



        calculateMargePercentDetail()
        {
            
            var total=0; 

            var margeabsolut=((parseFloat(this.detailForm.price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price); 
            var margeabsolutpseudo=((parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price); 

            // 100 / VK netto * EK netto 

            if (this.detailForm.pseudo_price <= 0 ) {
            
            total=100 / (parseFloat(this.detailForm.price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolut
            }

            else {
            total=100 / (parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolutpseudo
            }

            return total.toFixed(2); 
        },

        calculateMargeAbsolutDetail()
        {
            var total=0; 

            // 100 / VK netto * EK netto 

            if (this.detailForm.pseudo_price <= 0 ) {
            total=((parseFloat(this.detailForm.price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price) 
            }

            else {
            total=(parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) - parseFloat(this.detailForm.purchase_price) 
            }

            return total.toFixed(2); 
        },



        getVariationValue(index, group)
        {   
            // SUche in existingVariatnion(index) nach einem Eintrag 
            const result = this.existingVariations[index].values.find(value => value.group_id == group);
            
            return result.name;
        },

        producerChanged(event){
            const result = this.producers.find( producer => producer.id == event.target.value);
            //console.log(result);
            this.distributors = result.distributors;
        },

        saveImages(){
            this.$Progress.start();
            this.uploadImageForm.article_id = this.article.id;
            this.uploadImageForm
                .post("/articles/uploadImages")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bilder wurden hochgeladen",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteImage(uuid)
        {
            this.$swal({
                    title: "Möchtest du das Bild wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.$Progress.start();
                        this.axios
                            .post("/articles/deleteImage", {uuid: uuid, article_id: this.article.id})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Bild gelöscht",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.$Progress.finish();
                                this.loadArticle();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                                this.$Progress.fail();
                            });
                    }
                });
        },

        setDefaultImage(uuid){
            this.$Progress.start();
            this.axios
                .post("/articles/setDefaultImage", {uuid: uuid, article_id: this.article.id})
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bildertitel gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveImageData(){
            this.$Progress.start();
            this.imageDataForm
                .post("/articles/changeTitle")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bildertitel gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        selectImage(index)
        {
            //console.log(index);
            this.imageSelected = (index == this.imageSelected ? '' : index);
            this.selectedImage = this.article.media[index];
            this.imageDataForm.uuid = this.article.media[index].uuid;
            this.imageDataForm.title = this.article.media[index].custom_properties.title;
            
        },

        toggleHasVariation()
        {
            if(this.existingVariations.length >= 2)
            {
                this.form.hasVariations = true;
                this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Um Varianten abzuschalten, müssen alle Varianten bis auf die Basisvariante gelöscht werden.",
                            });
                
            }
        },

        deleteVariationValue(id)
        {
            this.$swal({
                title: "Möchtest du die Artikelvariante wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/articles/deleteVariation/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikelvariante erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadArticle();
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        editVariation(id){
            this.$Progress.start();
            this.detailForm
                .put("/articles/details/"  + id )
                .then(() => {
                    this.$bvModal.hide("editModal");
                    this.$swal({
                        icon: "success",
                        title: "Artikelvarianten wurden erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        createVariations(){
            this.$Progress.start();
            this.variationForm
                .post("/articles/" + this.$route.params.id + '/createVariations')
                .then(() => {
                    this.$bvModal.hide("variationModal");
                    this.$swal({
                        icon: "success",
                        title: "Artikelvarianten wurden erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        updateValues(event){
            this.loadVariationValues(event.target.value);
        },

        createModal() {
            this.$bvModal.show("variationModal");
            this.variationForm.selectedVariationValues = [];
            this.loadVariationValues(this.variationGroups[0].id);
        },

        editModal(article) {
            this.$bvModal.show("editModal");
            this.detailForm.fill(article);
        },

        handleDetailOk(bvModalEvt, id){
            bvModalEvt.preventDefault();
            this.handleDetailSubmit(id);
        },

        handleDetailSubmit(id){
            this.editVariation(id);
        },

        handleOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        handleSubmit(){
            this.createVariations();
        },

        pseudoPrice(value)
        {
            if(value == null)
            {
                return "Nicht definiert";
            }

            return value;
        },

        saveNutrition()
        {
            this.$Progress.start();
            this.nutrition
                .post("/articles/" + this.$route.params.id + '/updateNutrition')
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel Nährwerte wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveArticle(){
            this.$Progress.start();
            //this.form.test = this.test;
            this.form
                .put("/articles/" + this.$route.params.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel erfolgreich geändert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                    //this.$router.push('/articles');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadArticle() {
            this.$Progress.start();
            this.axios
                .get("/articles/" + this.$route.params.id)
                .then((response) => {
                    this.article = response.data.data;
                    this.form.fill(this.article);
                    if(this.article.nutrition != null)
                    {
                        this.nutrition.fill(this.article.nutrition);
                    }
                    // if(this.article.media != null)
                    // {
                    //     this.selectedImage = this.article.media.slice(0,1);
                    // }
                    this.existingVariations = this.article.variations;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCategories() {
            this.$Progress.start();
            this.axios
                .get("/categoriesArticleList")
                .then((response) => {
                    this.categories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadSeoCategories() {
            this.$Progress.start();
            this.axios
                .get("/seocategories")
                .then((response) => {
                    this.seoCategories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCustomerGroups() {
            this.$Progress.start();
            this.axios
                .get("/customer-groups")
                .then((response) => {
                    this.customerGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadDeposits() {
            this.$Progress.start();
            this.axios
                .get("/deposits")
                .then((response) => {
                    this.deposits = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadUnits() {
            this.$Progress.start();
            this.axios
                .get("/units")
                .then((response) => {
                    this.units = response.data.data;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadDistributors() {
            this.$Progress.start();
            this.axios
                .get("/distributors", { 
                    params: {
                        per_page: 150
                    }
                })
                .then((response) => {
                    this.distributors= response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadTaxes() {
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadVariationGroups() {
            this.$Progress.start();
            this.axios
                .get("/variations")
                .then((response) => {
                    this.variationGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadVariationValues(id) {
            this.$Progress.start();
            this.axios
                .get("/variations/" + id + "/values")
                .then((response) => {
                    this.variationValues = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.loadArticle();
        this.loadProducers();
        this.loadDistributors();
        this.loadTaxes();
        this.loadUnits();
        this.loadDeposits();
        //this.loadCustomerGroups();
        this.loadCategories();
        this.loadVariationGroups();
        this.loadSeoCategories();
    },

    computed: {
        tableData() {
            return this.existingVariations || [];
        },

        rows() {
            return this.existingVariations.length;
        },
    },
    


}
</script>

<style>

</style>